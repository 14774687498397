import React from 'react'
import './Program.css'
import { programsData } from '../../data/programsData' 
import rightArrow from '../../assets/rightArrow.png'
const Program = () => {
    return (
        <div className="program">
            <div className="program-header">
                <span className="program-header__title stroke-text">EXPLORE OUR</span>
                <span className="program-header__title">PROGRAMS</span>
                <span className="program-header__title stroke-text">TO SHAPE YOU</span>
            </div>
            <div className="program-categories">
                {programsData.map((datalist, i) => (
                    <div className="categories" key={i}>
                        {datalist.image}
                        <h3 className="categories-little">{datalist.heading}</h3>
                        <p className="categories-title">{datalist.details}</p>
                        <div className="categories-join">
                            <span className="categories-join__now">Join Now</span>
                            <img src={rightArrow} alt="" className="categories-join__img" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Program