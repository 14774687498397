import './App.css';
import Header from './component/header/header';
import Plancontainer from './component/Plancontainer/Plancontainer';
import Program from './component/Program/Program';
import Season from './component/Season/Season';
import Testtimonials from './component/Testtimonials/Testtimonials';
import Join from './component/Join/Join'
import Footer from './component/Footer/Footer';
function App() {
  return (
    <div className="App">
        <Header/>
        <Program/>
        <Season/>
        <Plancontainer/>
        <Testtimonials/>
        <Join/>
        <Footer/>
    </div>
  );
}

export default App;
