import React from 'react'
import Heading from '../Heading/Heading'
import './header.css'
import Heart from '../../assets/heart.png'
import Heroimg from '../../assets/hero_image.png' 
import Heroback from '../../assets/hero_image_back.png'
import Calori from '../../assets/calories.png'
const Header = () => {
    const mobile = window.innerWidth<=768 ? true : false; 
    return (
        <div className="header">
            <div className="header-blur blur"></div>
            <div className="left-h">
                <Heading/>
                <div className='the-best'>
                    <span className='the-best__fixed'></span>
                    <span className='the-best__title'>THE BEST FITNESS CLUB IN THE TOWN</span>
                </div>
                <div className="header-text">
                    <div className='header-text__line'>
                        <span className="header-text__title stroke-text">SHAPE </span>
                        <span className="header-text__title">YOUR</span>
                    </div>
                    <div>
                        <span className="header-text__title">IDEAL BODY</span>
                    </div>
                    <div className="header-title">In here we will help you to shape and build your ideal body and live up your life to fullest</div>
                </div>
                <div className="figures">
                    <div className="figures-num">
                        <div className="figures-num__data">+ 140</div>
                        <div className="figures-num__title">EXPERT COACHES</div>
                    </div>
                    <div className="figures-num">
                        <div className="figures-num__data">+ 978</div>
                        <div className="figures-num__title">MEMBERS JOINED</div>
                    </div>
                    <div className="figures-num">
                        <div className="figures-num__data">+ 50</div>
                        <div className="figures-num__title">FITNESS PROGRAMS</div>
                    </div>
                </div>
                <div className="header-button">
                    <button className="btn first-but">Get Started</button>
                    <button className="btn second-but">Learn More</button>
                </div>
            </div>
            <div className="right-h">
                <button className="but-join btn">
                    <a href="">Join Now</a>
                </button>
                <div className="heart">
                    <img src={Heart} alt="" className="heart-img" />
                    <span className="heart-litte">Heart Rate</span>
                    <span className="heart-bpm">116 bpm</span>
                </div>
                <img src={Heroimg} alt="" className="hero-img" />
                <img src={Heroback} alt="" className="hero-back" />
                <div className="calories">
                    <img src={Calori} alt="" className="calories-img" />
                    <div className="calories-list">
                        <span className="calories-list__num">Calories burned</span>
                        <span className="calories-list__kcal">220 kcal</span>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default Header