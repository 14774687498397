import React from 'react'
import './Plancontainer.css'
import { plansData } from '../../data/plansData'
import tickwhite from '../../assets/whiteTick.png'
const Plancontainer = () => {
  return (
        <div className="container">
            <div className="plans-blur-1 blur"></div>
            <div className="plans-blur-2 blur"></div>
            <div className="container-header">
                <span className="container-header__title stroke-text">READY TO START</span>
                <span className="container-header__title">YOUR JOURNEY</span>
                <span className="container-header__title stroke-text">NOW WITHUS</span>
            </div>
            <div className="container-plans">
                {plansData.map((planData, i) => (
                    <div className="container-plan" key={i}>
                        {planData.icon}
                        <span className="container-plan__name">{planData.name}</span>
                        <h1 className="container-plan__price">$ {planData.price}</h1>
                        <div className="container-plan__features">
                            {planData.features.map((feature, i) => (
                                <div className="feature" key={i}>
                                    <img src={tickwhite} alt="" className="feature-logo" />
                                    <span className="feature-title" key={i}>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <span className="container-benefits">See more benefits -></span>
                        <button className="button-container btn">Join Now</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plancontainer