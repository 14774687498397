import React, {useState} from 'react'
import './Testtimonials.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArr from '../../assets/leftArrow.png'
import rightArr from '../../assets/rightArrow.png'
const Testtimonials = () => {
    const [data, setData] = useState(0)
    const testLength = testimonialsData.length;
    const handleLeft = () => {
        data === 0 ? setData(testLength - 1) : setData((prev) => prev - 1)
    }
    const handleRight = () => {
        data === testLength - 1 ? setData(0) : setData((prev) => prev + 1)
    }
    return (
        <div className="testtimonials">
            <div className="left-testtimonial">
                <h2 className="testtimonial-topic">TESTIMONIALS</h2>
                <h3 className="testimonial-text stroke-text">What they</h3>
                <h3 className="testimonial-text">Say about us</h3>
            <p className="testtimonial-review">{testimonialsData[data].review}</p>
            <div className="testtimonial-tus">
                <span className="testtimonial-name">{testimonialsData[data].name}</span>
                <span className="testtimonial-status"> - {testimonialsData[data].status}</span>
            </div>
            </div>
            <div className="right-testtimonial">
                <div className="border-img"></div>
                <div className="background-img"></div>
                <img src={testimonialsData[data].image} alt="" className="testtimonial-img" />
                <div className="testtimonial-arrow">
                    <img onClick={handleLeft} src={leftArr} alt="" className="testt-left" />
                    <img onClick={handleRight} src={rightArr} alt="" className="testt-right" />
                </div>
            </div>
        </div>
    )
}

export default Testtimonials