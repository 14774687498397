import React, { useState } from 'react'
import './Heading.css'
import Logo from '../../assets/logo.png'
import bars from '../../assets/bars.png'
// import { Link } from 'react-scroll'
const Heading = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="heading">
        <img className='logo' src={Logo} alt=""/>
        {menuOpen === false && mobile === true ? (
          <div className="logo-bars" 
            onClick={() => setMenuOpen(true)}
          >
            <img src={bars} alt="" className="bars" />
          </div>
          ) : (
          <ul className='heading-menu'>
              <li className="heading-menu__item" onClick={() => setMenuOpen(false)}>Home</li>
              <li className="heading-menu__item" onClick={() => setMenuOpen(false)}>Programs</li>
              <li className="heading-menu__item" onClick={() => setMenuOpen(false)}>Why us</li>
              <li className="heading-menu__item" onClick={() => setMenuOpen(false)}>Plans</li>
              <li className="heading-menu__item" onClick={() => setMenuOpen(false)}>Testimonials</li>
          </ul>
          )
        }
    </div>
  )
}

export default Heading