import React from 'react'
import './Season.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import tick from  '../../assets/tick.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
const Season = () => {
    return (
        <div className="season">
            <div className="left-season">
                <img src={image1} alt="" className="number-one" />
                <img src={image2} alt="" className="number-two" />
                <img src={image3} alt="" className="number-three" />
                <img src={image4} alt="" className="number-four" />
            </div>
            <div className="right-season">
                <span className="reason-title">Some Reasons</span>
                <div className="reason-text">
                    <span className="reason-text-stroke stroke-text">Why </span>
                    <span className="reason-text-boid">Choose us?</span>
                </div>
                <div className="details">
                    <div className="details-line">
                        <img src={tick} alt="" className="details-img" />
                        <span className="details-title">OVER 140+ EXPERT COACHS</span>
                    </div>
                    <div className="details-line">
                        <img src={tick} alt="" className="details-img" />
                        <span className="details-title">TRAIN SMARTER AND FASTER THAN BEFORE</span>
                    </div>
                    <div className="details-line">
                        <img src={tick} alt="" className="details-img" />
                        <span className="details-title">1 FREE PROGRAM FOR NEW MEMBER</span>
                    </div>
                    <div className="details-line">
                        <img src={tick} alt="" className="details-img" />
                        <span className="details-title">RELIABLE PARTNERS</span>
                    </div>
                </div>
                <span className="details-partner">OUR PARTNERS</span>
                <div className="details-logo">
                    <img src={nb} alt="" className="details-logo__img" />
                    <img src={adidas} alt="" className="details-logo__img" />
                    <img src={nike} alt="" className="details-logo__img" />
                </div>
            </div>
        </div>
    )
}
export default Season