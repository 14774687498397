import React from 'react'
import './Footer.css'
import logoFooter from '../../assets/logo.png'
import github from '../../assets/github.png'
import instagrem from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
const Footer = () => {
  return (
    <div className="footer">
      <hr />
      <div className="footer-container">
        <div className="footer-social">
          <a href="">
            <img src={github} alt="" />
          </a>
          <a href="">
            <img src={instagrem} alt="" />
          </a>
          <a href="">
            <img src={linkedin} alt="" />
          </a>
        </div>
        <div className="footer-logo">
          <img src={logoFooter} alt="" />
        </div>
        <div className="blur-footer-left blur"></div>
        <div className="blur-footer-right blur"></div>
      </div>
    </div>
  )
}

export default Footer