import React, { useRef } from 'react'
import './Join.css'
const Join = () => {
  const form = useRef()
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_b9t8t21', 'template_7a991b1', form.current, '1q76Igr3o_xeVe3xS')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // };
  return (
    <div className="join" id="#join">
      <div className="left-join">
        <hr />
        <div className="join-text">
          <span className="stroke-text">ready to</span>{" "}
          <span>level up</span>
        </div>
        <div className="join-text">
          <span>your body</span>{" "}
          <span className="stroke-text">with us?</span>
        </div>
      </div>
      <div className="right-join">
        <form ref={form} action="" className="join-email">
          <input type="email" placeholder='Enter your Email Address here ...' className="input-email" />
          <button className="button-email btn">Join Now</button>
        </form>
      </div>
    </div>
  )
}

export default Join